import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Row, Col } from "../components/page-components/grid";
import Img from "gatsby-image";
import SEO from "../components/seo";
import MD from "gatsby-custom-md";
import "../style/basepage.less";
import Contact from "../components/contact";

const components = {
    row: Row,
    col: Col
}

export default function({ data }) {
    return (
        <Layout>
            <SEO
                lang="en"
                title={data.markdownRemark.frontmatter.title}
                description={data.markdownRemark.frontmatter.description}
            />
            <div className="container">
                <article className="post">
                    <div className="head text-primary">
                        <h1>{data.markdownRemark.frontmatter.title}</h1>
                    </div>
                    <div className="content row flex">
                        {data.markdownRemark.frontmatter.image && (
                            <div className="center">
                                <div className="img">
                                    <Img
                                        fluid={
                                            data.markdownRemark.frontmatter
                                                .image.childImageSharp.fluid
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="content row flex">
                        <div
                            className="col s12">
                            <MD
                                components={components}
                                htmlAst={data.markdownRemark.htmlAst}
                            />
                        </div>
                    </div>
                </article>
                <Contact />
            </div>
        </Layout>
    );
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            htmlAst
            frontmatter {
                title
                description
                image {
                    publicURL
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            srcSet
                            ...GatsbyImageSharpFluid
                        }
                        id
                    }
                }
            }
        }
    }
`;
